<template>
	<div class=''>
		<!-- 轮播图 -->
		<div class="header">
			<van-swipe :autoplay="3000" indicator-color='#F7B500'>
				<van-swipe-item v-for="(image, index) in bannerImg" :key="index">
					<img v-lazy="image.wapPic" class="w-max h415" @click="jumpD(image)" />
				</van-swipe-item>
			</van-swipe>
		</div>
		<!-- //轮播图 -->
		<!-- 产品中心 -->
		<div class="mt20 pl12 pr12">
			<div class="fs18 text-center fw-600">产品中心</div>
			<div class="fs11 color9D text-center mt4 pl38 pr38 mb12">
				铭智生科拥有强大的研发能力，产品线涉及微酸性碳酸型次氯酸消毒产品，免激活一元性二氧化氯消毒产品，基于物联网技术及自有专利技术的等离子体、水离子智慧防疫设备，综合公共卫生管理系统等，通过丰富的多元化产品，组建起全维度的公共卫生服务体系。
			</div>
			<!-- 图片列表 -->
			<div class="flex flex-wrap">
				<div v-for="(item,i) in goodsList" :key="i" class="mr11 relative goods_img mb12">
					<img :src="item.wapPic" class="w170 h200" :class="{'w-max':i==0,'h205':i==0}"
						@click="gotoGoodsD(item)">
					<div class="fs12 fw-600 color-f absolute goods_sub">{{item.bannerName}}</div>
				</div>

			</div>
			<!-- //图片列表 -->
			<!-- <div class="look_box">查看更多</div> -->
			<div class="flex row-center">
				<van-button color="#272727" plain @click="$router.push('/goodsCenter')">查看更多</van-button>
			</div>
		</div>
		<!-- //产品中心 -->

		<!-- 新闻活动 -->
		<div class="mt30 pl12 pr12">
			<div class="fs18 text-center fw-600">新闻与活动</div>
			<div class="fs11 color9D text-center mt4 pl38 pr38 mb12">铭智生科最新资讯</div>
			<div style="background: #F9F9F9;" class="mb16" v-for="(item,i) in newsList" :key="i"
				@click="$router.push(`/newsD/${item.id}`)">
				<img :src="item.wapPic" class="w-max h242">
				<div class="pt12 pb12 pl12 pr12" style="color: #4A4A4A;">
					<div class="fs14 fw-500">{{item.contentName}}</div>
					<div class="flex row-between mt4">
						<div class="fs12">{{item.releaseTime}}</div>
						<div class="fs12 color-y">查看详情></div>
					</div>
				</div>
			</div>

			<div class="flex row-between pl41 pr41">
				<van-button color="#272727" plain @click="$router.push('/news')">更多新闻</van-button>
				<van-button color="#272727" plain>更多活动</van-button>
			</div>
		</div>
		<!-- //新闻活动 -->

		<!-- 合作案例 -->
		<div class="mt77 case_box w-max h227">
			<div class="w188 h96 color0  pt15 pl15 pr15 case_content" style="background: #F7B500;">
				<div class="fs18 fw-600 ">合作案例</div>
				<div class="fs11 mt4">铭智生科大型合作案例展示。</div>
			</div>
			<div class="w234 h132 bg-0 flex col-center row-center video_box">
				<video src="http://download.e-rijiben.com/movie/mzbiotech.mp4" controls loop autoplay muted
					class="play_box" ref="videoDom"></video>
				<!-- <van-icon name="play-circle-o" color="#fff" /> -->
				<!-- <van-icon name="pause-circle-o" color="#fff"/> -->
			</div>
		</div>
		<!-- //合作案例 -->

		<!-- 合作伙伴 -->
		<div class="mt30">
			<div class="fs18 text-center fw-600 mb12">合作伙伴</div>
			<div class="fs11 color9D text-center mt4 pl38 pr38 mb12">
				铭智生科致力于公共卫生领域与科学防疫领域，经过多年的诚信经营、依靠优质的服务体系和过硬的产品质量，收获了各个领域的企事业与机关单位的支持信任。</div>
			<div class="pr12 pl12 flex flex-wrap">
				<img class="w111 h72 partner_itembox mr9 mb8" style="object-fit: cover;" :src="item.img"
					v-for="(item,i) in imgList" :key="i" />
			</div>
		</div>
		<!-- //合作伙伴 -->

		<!-- 团队介绍 -->
		<div class="mt32 w-max bg-0 pt16 color-f pb30">
			<div class="fs18 text-center fw-600 mb12">团队介绍</div>
			<div class="fs11 mt4 text-center pl32 pr32">
				我们是一支由多名医疗卫生系统具有多年经验的专业人才组成的创业团队，作为国内首批从事于公共卫生领域的企业，我们有着宝贵的防疫经验与科学的管理体系，团队成员充满朝气、具有创新意识并勇于迎接挑战，愿为改善中国公共卫生现状，贡献出自己的力量。 
			</div>
			<!-- 团队介绍轮播图 -->
			<van-swipe :autoplay="3000" indicator-color='#F7B500' class="mt16">
				<van-swipe-item v-for="(item, index) in teamList" :key="index">
					<!-- <img v-lazy="image" class="w-max h415" /> -->
					<div class="w343 pb28 flex row-center col-center pt24"
						style="background: rgba(255, 255, 255, .19);margin: 0 auto;">
						<div class="w275  relative">
							<img v-lazy="item.wapPic" class="w-max h309" />
							<div class="flex row-column col-center mt12">
								<div class="fs20 fw-600 color-y">{{item.memberName}}</div>
								<div class="fs12 fw-600 color-y mt6">{{item.memberPosition}}</div>
							</div>
						</div>
					</div>
				</van-swipe-item>
			</van-swipe>
			<!-- //团队介绍轮播图 -->
		</div>
		<!-- //团队介绍 -->

		<!-- 为什么选择我们 -->
		<div class="mt30 color0 pb30">
			<div class="fs18 text-center fw-600 ">为什么选择我们？</div>
			<div class="fs11 mt4 text-center mr50 ml50 color9D">完整的防疫体系、优质的服务、权威的检测报告、独有的发明专利。铭智生科将为您在公共卫生防疫的领域内保驾护航。
			</div>
			<div class="flex flex-wrap">
				<div class="text-center mt30" style="width: 50%;" v-for="(item,i) in serveList" :key="i">
					<div class="fs20 fw-600">{{item.num}}</div>
					<div class="mt10 fs12">{{item.title}}</div>
				</div>
			</div>
		</div>
		<!-- //为什么选择我们 -->

		<!-- 视频弹层 -->
		<!-- 	<van-popup v-model="showVideo" :closeable='true' class="w350 h350">
				<video src="https://media.w3.org/2010/05/sintel/trailer.mp4" autoplay controls v-if="showVideo"
					class="w-max h-max"></video>
			</van-popup> -->
	</div>
</template>

<script>
	import { getForwardBanners, getNewsAndActivity, getTeams } from '@/api'
	export default {
		name: 'home',
		data() {
			return {
				//视频弹层
				showVideo: false,
				//轮播图
				bannerImg: [],
				//产品列表
				goodsList: [],
				// 新闻列表
				newsList: [],
				//团队列表
				teamList: [],
				serveList: [{
					num: '1000,000+',
					title: '服务人群'
				}, {
					num: '100+',
					title: '服务城市'
				}, {
					num: '99.99%',
					title: '产品好评率'
				}, {
					num: '99.99%',
					title: '服务好评率'
				}],
				imgList: [{
						img: require('@/assets/images/partner/12306.webp')
					}, {
						img: require('@/assets/images/partner/beijing.webp')
					}, {
						img: require('@/assets/images/partner/chongqing.webp')
					}, {
						img: require('@/assets/images/partner/guoneng.webp')
					}, {
						img: require('@/assets/images/partner/guoyao.webp')
					}, {
						img: require('@/assets/images/partner/lepinhui.webp')
					}, {
						img: require('@/assets/images/partner/lepu.webp')
					}, {
						img: require('@/assets/images/partner/luhe.webp')
					}, {
						img: require('@/assets/images/partner/nongyedaxue.webp')
					},
					{
						img: require('@/assets/images/partner/quanjude.webp')
					}, {
						img: require('@/assets/images/partner/red.webp')
					}, {
						img: require('@/assets/images/partner/shoulv.webp')
					},
					{
						img: require('@/assets/images/partner/yidong.webp')
					},
					{
						img: require('@/assets/images/partner/yuanxin.webp')
					}, {
						img: require('@/assets/images/partner/zhejiangyiliao.webp')
					},
					// {
					// img: require '@/assets/images/partner/yidong.webp'
					// }, {
					// img: require '@/assets/images/partner/zhejiangyiliao.webp'
					// }
				]
			};
		},

		created() {
			//首页类型：轮播图 
			this.getForwardBanners()
			// 产品中心
			this.getGoodslist()
			//获取新闻活动
			this.getNewsAndActivity()
			//首页团队介绍
			this.getTeams()

		},

		mounted() {},

		methods: {
			//banner轮播图
			async getForwardBanners() {
				const res = await getForwardBanners({ bannerType: 1 })
				this.bannerImg = res.data
			},
			//获取产品中心列表
			async getGoodslist() {
				const res = await getForwardBanners({ bannerType: 2 })
				this.goodsList = res.data
			},
			//获取新闻活动
			async getNewsAndActivity() {
				const res = await getNewsAndActivity()
				this.newsList = res.data
			},
			//获取首页团队介绍
			async getTeams() {
				const res = await getTeams()
				let obj = {
					wapPic: require('@/assets/images/home/ceo.jpg'),
					memberName: '王涛',
					memberPosition: '董事长'
				}
				res.data.unshift(obj)
				this.teamList = res.data
			},
			//benner轮播图跳转
			jumpD(item) {
				if (item.isForward == 1) {
					let url = ''
					if (item.forwardHead == 1) {
						url = '/coreJiShuD' //核心技术详情
					} else if (item.forwardHead == 2) {
						url = '/goodsD' //产品中心详情
					} else if (item.forwardHead == 3) {
						url = '/solutionD' //解决方案详情
					} else if (item.forwardHead == 5) {
						url = '/patentD' //专利报告
					} else if (item.forwardHead == 7) {
						url = '/newsD' //新闻详情
					}
					this.$router.push(`${url}/${item.forwardContentId}`)
				}
			},
			gotoGoodsD(item) {
				this.$router.push(`/goodsD/${item.forwardContentId}`)
			}
		}
	}
</script>
<style lang='scss' scoped>
	/* //指示器显示到外面 */
	/* 	::v-deep .van-swipe {
		padding-bottom: 8px;
		overflow: auto;
		overflow-x: hidden;
	} */

	/* 	::v-deep .van-swipe__indicators {
		bottom: -8px;
	} */
	img {
		display: block;
	}

	.header {
		::v-deep .van-swipe__indicator {
			background-color: rgba(0, 0, 0, .19)
		}
	}

	.goods_img:nth-child(2n+1) {
		margin-right: 0 !important;
	}

	.goods_sub {
		width: 100%;
		height: 41px;
		line-height: 41px;
		padding-left: 12px;
		background: linear-gradient(180deg, rgba(74, 74, 74, 0) 0%, rgba(0, 0, 0, 0.47) 100%);
		/* bottom: 12px; */
		bottom: 0;
	}

	::v-deep .van-button {
		width: 127px;
		height: 28px;
		font-size: 12px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #272727;
	}

	.case_box {
		position: relative;
		background: url('~@/assets/images/home/videoBg.png') no-repeat;
		background-size: 100% 100%;
		border: .1px solid transparent;

		.case_content {
			margin-top: -44px;
			margin-left: 24px;
		}

		.video_box {
			border: 4px solid #fff;
			margin: 0 auto;
			margin-top: 28px;
		}
	}

	.partner_itembox:nth-child(3n) {
		margin-right: 0 !important;
	}

	.team_name {
		position: absolute;
		left: 0;
		bottom: 0;
		transform: translateY(50%);
	}

	.play_box {
		width: 100%;
		height: 100%;
		background: #000000;
		/* border: 12px solid #ffffff; */
	}
</style>
